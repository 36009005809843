<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Identify the type of crystalline solid as metallic (Met), network covalent (Net), ionic
        (Ion), or molecular (Mol)) formed by each of the following substances:
      </p>

      <v-simple-table style="max-width: 550px">
        <thead>
          <tr>
            <th style="font-size: 15px">Substance</th>
            <th style="font-size: 15px">Met</th>
            <th style="font-size: 15px">Net</th>
            <th style="font-size: 15px">Ion</th>
            <th style="font-size: 15px">Mol</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(molecule, i) in molecules" :key="i">
            <td>
              <chemical-latex :content="molecule" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs[`input${i + 1}`]" :disabled="!allowEditing">
                <v-radio key="metal" value="metal" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs[`input${i + 1}`]" :disabled="!allowEditing">
                <v-radio key="netCov" value="netCov" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs[`input${i + 1}`]" :disabled="!allowEditing">
                <v-radio key="ion" value="ion" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs[`input${i + 1}`]" :disabled="!allowEditing">
                <v-radio key="molec" value="molec" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question388',
  components: {
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
        input9: null,
      },
      molecules: ['SiO2', 'KCl', 'Cu', 'CO2', 'C (diamond)', 'BaSO4', 'NH3', 'NH4F', 'C2H5OH'],
    };
  },
};
</script>
